import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ArrowRight, Clock, DollarSign, Briefcase, FileText, ChevronDown, ChevronUp, Globe, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import blogPosts from './blogPosts';

const LanguageSwitcher = ({ currentLanguage, onLanguageChange }) => {
  return (
    <div className="flex space-x-2">
      <button onClick={() => onLanguageChange('en')} className={`w-8 h-8 rounded-full overflow-hidden ${currentLanguage === 'en' ? 'ring-2 ring-yellow-400' : ''}`}>
        <img src="/images/flag-en.png" alt="English" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onLanguageChange('zh')} className={`w-8 h-8 rounded-full overflow-hidden ${currentLanguage === 'zh' ? 'ring-2 ring-yellow-400' : ''}`}>
        <img src="/images/flag-zh.png" alt="中文" className="w-full h-full object-cover" />
      </button>
    </div>
  );
};

const LandingPage = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  const [language, setLanguage] = useState('en');


  useEffect(() => {
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-XC92ZVTWX5'); 
  
    // Load Chatbase after a 5-second delay
    const chatbaseTimer = setTimeout(() => {
      const script = document.createElement('script');
      script.src = 'https://www.chatbase.co/embed.min.js';
      script.id = 'DWDOYkg4h9xi3NeiIRWKR'; // Replace with your Chatbase Bot ID
      script.domain = 'www.chatbase.co';
      document.body.appendChild(script);
    }, 5000); // Prevents bot spam by delaying execution
  
    return () => clearTimeout(chatbaseTimer); // Cleanup function
  }, []);
  

  const content = {
    en: {
      title: "Easy Visa Cambodia | Expert Visa Extensions and Guidance for Phnom Penh, Siem Reap",
      description: "Professional visa extension services in Cambodia. Navigate regulations with ease. Efficient assistance for tourist and ordinary visas in Phnom Penh and Siem Reap.",
      menuItems: [
        { name: 'Blog', href: '/blog' },
        { name: 'Services', href: '#services' },
        { name: 'Our Process', href: '#process' },
        { name: 'FAQ', href: '#faq' },
        { name: 'Contact', href: '#contact' }
      ],
      hero: {
        title: "Expert Cambodia Visa Extensions and Guidance",
        subtitle: "Navigate your stay in Cambodia with confidence. Professional assistance for visa extensions and options in Phnom Penh and Siem Reap. Stay compliant effortlessly.",
        cta: "Explore Your Visa Options",
      },
      benefits: {
        title: "Why Choose Easy Visa Cambodia?",
        items: [
          {
            title: "Save Time and Effort",
            description: "Skip the confusion of visa regulations. We handle the entire process, allowing you to focus on enjoying your time in Cambodia.",
          },
          {
            title: "Up-to-Date Expertise",
            description: "Cambodia's visa regulations change frequently. We stay current with all updates, ensuring your visa strategy is always compliant and optimal for your needs.",
          },
          {
            title: "Clear, Transparent Pricing",
            description: "No hidden fees or surprises. Understand exactly what you're paying for, whether it's a visa extension or guidance on your visa options.",
          },
        ],
      },
      services: {
        title: "Our Visa Services for Phnom Penh & Siem Reap",
        types: [
          { 
            name: 'Tourist Visa (T) Extension', 
            duration: 'One-time 30-day extension', 
            price: 'From $45', 
            color: 'bg-green-100', 
            icon: Clock,
          },
          { 
            name: 'Ordinary Visa (E) Extension', 
            duration: '1, 3, 6, or 12 months', 
            price: 'From $50', 
            color: 'bg-blue-100', 
            icon: FileText,
          },
          { 
            name: 'Business Visa Guidance', 
            duration: 'Various options', 
            price: 'Custom pricing', 
            color: 'bg-yellow-100', 
            icon: Briefcase,
          },
          { 
            name: 'Visa Strategy Consultation', 
            duration: 'Tailored advice', 
            price: 'Contact us', 
            color: 'bg-red-100', 
            icon: DollarSign,
          }
        ],
        learnMore: "Learn More",
        fastProcessing: "Efficient processing available",
      },
      process: {
        title: "Our Simple Process",
        steps: [
          {
            title: "Initial Consultation",
            description: "Share your situation with us. We'll assess your needs and provide expert advice on the best visa strategy for your stay in Cambodia.",
          },
          {
            title: "We Handle the Paperwork",
            description: "We collect your passport and manage all necessary interactions with immigration authorities, saving you time and potential stress.",
          },
          {
            title: "Receive Your Visa",
            description: "Get your passport back with the new visa or extension, allowing you to continue your Cambodian journey worry-free.",
          },
        ],
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "How long does the visa extension process take?",
            answer: "Typically 7-10 days. We offer express service for urgent extensions, usually 1-2 days. We recommend starting the process at least 7 days before your visa expires to avoid complications and overstay fees."
          },
          {
            question: "What are the visa extension options?",
            answer: "The extension options depend on your visa type:\n\n1. Tourist Visa (T Visa): Can only be extended once for 30 days.\n2. Ordinary Visa (E Visa): Can be extended for 1, 3, 6, or 12 months. Different subtypes include:\n   - EB (business)\n   - EG (general, including for those looking for work)\n   - ER (retirement, for those 55 or older)\n   - ES (student)\n\nEach extension type may have specific requirements and documentation needs."
          },
          {
            question: "Do I need to leave Cambodia to extend my visa?",
            answer: "For E Visa extensions, you don't need to leave Cambodia. We can handle the entire process without you leaving the country. However, if you have a T Visa and have already used your one-time extension, you'll need to exit and re-enter Cambodia to obtain a new visa. We can advise on the best course of action for your situation."
          },
          {
            question: "What documents do I need for a visa extension?",
            answer: "Generally, you'll need your passport, a passport-sized photo, and the visa extension fee. For longer E Visa extensions (6 or 12 months), additional documents may be required, such as a work permit or business registration. We'll guide you through the specific requirements based on your visa type and extension duration."
          },
          {
            question: "Can I work on a tourist visa in Cambodia?",
            answer: "No, working on a tourist visa (T Visa) is not legal in Cambodia. If you plan to work, you'll need to obtain an E Visa and the appropriate work permit. We can assist you with this process to ensure compliance with Cambodian labor laws."
          },
          {
            question: "How many times can I extend my visa?",
            answer: "The number of extensions depends on your visa type:\n\n- Tourist Visa (T Visa): Can only be extended once for 30 days.\n- Ordinary Visa (E Visa): Can typically be extended multiple times, potentially allowing for long-term stays.\n\nWe can provide specific advice based on your situation and the latest immigration regulations."
          },
          {
            question: "Can I convert my Tourist Visa to an Ordinary Visa without leaving Cambodia?",
            answer: "No, it's not possible to convert a Tourist Visa (T Visa) to an Ordinary Visa (E Visa) within Cambodia. If you enter on a T Visa and decide you want to stay longer or work, you'll need to leave the country and re-enter with an E Visa. We can advise you on the best way to manage this transition."
          }
        ],
      },
      contact: {
        title: "Ready to Extend Your Stay in Cambodia?",
        subtitle: "Contact us now for fast visa extension services in Phnom Penh and Siem Reap!",
        cta: "Call Us Now",
        telegram: "Write Us on Telegram",
        offices: {
          phnomPenh: "Phnom Penh Office",
          siemReap: "Siem Reap Office",
        },
      },
      footer: {
        description: "Making visa extensions simple and stress-free in Phnom Penh and Siem Reap.",
        quickLinks: "Quick Links",
        links: [
          { name: "Home", href: "/" },
          { name: "Services", href: "#services" },
          { name: "Our Process", href: "#process" },
          { name: "FAQ", href: "#faq" },
        ],
        copyright: "© 2024 Easy Visa Cambodia. All rights reserved.",
      },
    },
    zh: {
      title: "轻松柬埔寨签证 | 金边、暹粒专业签证延期和咨询服务",
      description: "柬埔寨专业签证延期服务。无需排队，轻松应对法规。在金边和暹粒为旅游、普通和商务签证提供专业协助。",
      menuItems: [
        { name: '博客', href: '/blog' },
        { name: '服务', href: '#services' },
        { name: '流程', href: '#process' },
        { name: '常见问题', href: '#faq' },
        { name: '联系我们', href: '#contact' }
      ],
      hero: {
        title: "轻松办理柬埔寨签证延期和咨询",
        subtitle: "在柬埔寨安心延长您的停留时间。在金边和暹粒提供专业的签证延期和选择指导。轻松应对签证法规。",
        cta: "探索签证选项",
      },
      benefits: {
        title: "为什么选择轻松柬埔寨签证？",
        items: [
          {
            title: "高效流程",
            description: "无需在移民局长时间排队。我们处理整个过程，让您专注于享受柬埔寨之旅。",
          },
          {
            title: "最新法规知识",
            description: "柬埔寨的签证政策经常变化。我们时刻关注所有更新，确保您的签证策略始终合规且最适合您的需求。",
          },
          {
            title: "透明定价",
            description: "费用结构完全透明。无论是签证延期还是签证选择咨询，您都能清楚了解每项费用。",
          },
        ],
      },
      services: {
        title: "金边和暹粒全面签证服务",
        types: [
          { 
            name: '旅游签证（T签证）延期', 
            duration: '一次性30天延期', 
            price: '起价$45', 
            color: 'bg-green-100', 
            icon: Clock,
          },
          { 
            name: '普通签证（E签证）延期', 
            duration: '1、3、6或12个月', 
            price: '起价$50', 
            color: 'bg-blue-100', 
            icon: FileText,
          },
          { 
            name: '商务签证指导', 
            duration: '多种选择', 
            price: '定制价格', 
            color: 'bg-yellow-100', 
            icon: Briefcase,
          },
          { 
            name: '签证策略咨询', 
            duration: '根据您的需求定制', 
            price: '联系我们', 
            color: 'bg-red-100', 
            icon: DollarSign,
          }
        ],
        learnMore: "了解更多",
        fastProcessing: "高效处理服务",
      },
      process: {
        title: "我们的简化流程",
        steps: [
          {
            title: "初步咨询",
            description: "向我们介绍您的情况。我们将评估您的需求，并为您在柬埔寨的停留提供最佳签证策略建议。",
          },
          {
            title: "无忧管理",
            description: "我们收集您的护照并处理与移民部门的所有必要互动，为您节省时间和潜在的压力。",
          },
          {
            title: "签证获得",
            description: "领取带有新签证或延期的护照，让您无忧继续您的柬埔寨之旅。",
          },
        ],
      },
      faq: {
        title: "常见问题",
        items: [
          {
            question: "签证延期过程需要多长时间？",
            answer: "通常需要3-5个工作日。我们为紧急延期提供快速服务。我们建议在签证到期前至少7天开始申请流程，以避免复杂情况和逾期费用。"
          },
          {
            question: "有哪些签证延期选项？",
            answer: "主要有三种延期类型：\n\n1. EG（求职）：可选1、3或6个月。您需要2张护照尺寸的照片。\n2. EB（商务）：可选1、3、6或12个月。需要盖章的雇佣证明信、2张护照照片，以及工作许可（额外费用）。\n3. ER（退休）：适用于55岁及以上人士。"
          },
          {
            question: "我需要离开柬埔寨才能延长签证吗？",
            answer: "不需要，我们的服务允许您不离开柬埔寨就可以延长签证，无需进行签证跑。我们代表您处理金边移民局的所有文书工作。"
          },
          {
            question: "签证延期需要哪些文件？",
            answer: "通常，您需要护照、护照尺寸的照片和签证延期费用。对于6个月和12个月的延期，可能需要额外文件，如雇佣证明或有效的工作许可。我们将根据您的具体情况指导您完成所需的要求。"
          },
          {
            question: "我可以用旅游签证在柬埔寨工作吗？",
            answer: "不可以，在柬埔寨使用旅游签证工作是非法的。如果您计划工作，您需要获得适当的商务签证和工作许可。我们可以协助您完成此过程，确保符合柬埔寨劳动法。"
          },
          {
            question: "我可以延长签证多少次？",
            answer: "延期次数取决于您的签证类型。旅游签证的延期选择有限，而普通（E）签证通常可以多次延期。我们可以根据您的情况和最新的移民规定提供具体建议。"
          },
        ],
      },
      contact: {
        title: "准备好延长您在柬埔寨的停留时间了吗？",
        subtitle: "立即联系我们，在金边和暹粒获得快速签证延期服务！",
        cta: "立即致电",
        telegram: "通过Telegram联系我们",
        offices: {
          phnomPenh: "金边办事处",
          siemReap: "暹粒办事处",
        },
      },
      footer: {
        description: "在金边和暹粒让签证延期变得简单和无忧。",
        quickLinks: "快速链接",
        links: [
          { name: "首页", href: "#" },
          { name: "服务", href: "#services" },
          { name: "我们的流程", href: "#process" },
          { name: "常见问题", href: "#faq" },
        ],
        copyright: "© 2024 轻松柬埔寨签证。保留所有权利。",
      },
    }
  };

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="font-sans bg-gray-50 text-gray-800">
      <Helmet>
        <title>{content[language].title}</title>
        <meta name="description" content={content[language].description} />
        <link rel="canonical" href={`https://www.easyvisacambodia.com/${language}`} />

         {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.easyvisacambodia.com/${language}`} />
        <meta property="og:site_name" content="Easy Visa Cambodia" />
        <meta property="og:title" content={content[language].title} />
        <meta property="og:description" content={content[language].description} />
        <meta property="og:image" content="https://www.easyvisacambodia.com/images/og-image.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`https://www.easyvisacambodia.com/${language}`} />
        <meta name="twitter:title" content={content[language].title} />
        <meta name="twitter:description" content={content[language].description} />
        <meta name="twitter:image" content="https://www.easyvisacambodia.com/images/og-image.jpg" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XC92ZVTWX5"></script>
       
      </Helmet>

      <header className="bg-blue-600 text-white p-4 sticky top-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <a href="/" className="text-2xl font-bold">Easy Visa Cambodia</a>
          <div className="flex items-center space-x-6">
            <nav className="hidden md:flex space-x-6">
              {content[language].menuItems.map((item, index) => (
                <a key={index} href={item.href} className="hover:text-blue-200 transition duration-150 ease-in-out">
                  {item.name}
                </a>
              ))}
            </nav>
            <LanguageSwitcher currentLanguage={language} onLanguageChange={setLanguage} />
          </div>
        </div>
      </header>

      <main>
        <section className="bg-gradient-to-r from-blue-500 to-blue-600 text-white py-20">
          <div className="container mx-auto text-center px-4">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">{content[language].hero.title}</h1>
            <p className="text-xl mb-8">{content[language].hero.subtitle}</p>
            <a href="#services" className="bg-yellow-500 text-blue-900 font-bold py-3 px-8 rounded-full text-lg hover:bg-yellow-400 transition duration-300 inline-flex items-center">
              {content[language].hero.cta} <ArrowRight className="ml-2" />
            </a>
          </div>
        </section>

        <section id="benefits" className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{content[language].benefits.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {content[language].benefits.items.map((benefit, index) => (
                <div key={index} className="text-center">
                  {index === 0 && <Clock className="w-16 h-16 text-blue-500 mx-auto mb-4" />}
                  {index === 1 && <Globe className="w-16 h-16 text-blue-500 mx-auto mb-4" />}
                  {index === 2 && <DollarSign className="w-16 h-16 text-blue-500 mx-auto mb-4" />}
                  <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="services" className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{content[language].services.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {content[language].services.types.map((visa, index) => (
                <div key={index} className={`${visa.color} p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col justify-between aspect-square`}>
                  <div>
                    <div className="flex items-center justify-between mb-4">
                      <visa.icon className="w-10 h-10 text-blue-600" />
                      <span className="font-bold text-sm text-blue-600">{visa.duration}</span>
                    </div>
                    <h3 className="text-xl font-bold mb-2">{visa.name}</h3>
                    <p className="text-sm mb-4">{content[language].services.fastProcessing}</p>
                  </div>
                  <div>
                    <p className="font-bold text-xl text-blue-600 mb-4">{visa.price}</p>
                    <a href="#contact" className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 inline-block w-full text-center">
                      {content[language].services.learnMore}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="process" className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{content[language].process.title}</h2>
            <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 md:space-x-12">
              {content[language].process.steps.map((step, index) => (
                <div key={index} className="text-center max-w-xs">
                  <div className="bg-blue-500 text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4 text-2xl font-bold">{index + 1}</div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="faq" className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{content[language].faq.title}</h2>
            <div className="space-y-4">
              {content[language].faq.items.map((item, index) => (
                <div key={index} className="border-b border-gray-200 pb-4">
                  <button
                    className="flex justify-between items-center w-full text-left"
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="text-xl font-semibold">{item.question}</span>
                    {openFAQ === index ? <ChevronUp /> : <ChevronDown />}
                  </button>
                  {openFAQ === index && (
                    <p className="mt-2 text-gray-600">{item.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="blog" className="py-16 bg-gray-100">
  <div className="container mx-auto px-4">
    <h2 className="text-3xl font-bold text-center mb-12">Latest from the Blog</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
      {blogPosts.slice(0, 4).map((post) => (
        <div key={post.id} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-bold mb-2">{post[language].title}</h3>
          <p className="text-gray-600 mb-4">{post[language].excerpt}</p>
          <Link to={`/blog/${post[language].slug}`} className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300">
            {language === 'en' ? 'Read More' : '阅读更多'}
          </Link>
        </div>
      ))}
    </div>
    <div className="text-center mt-8">
      <Link to="/blog" className="bg-blue-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-blue-600 transition duration-300">
        {language === 'en' ? 'View All Posts' : '查看所有文章'}
      </Link>
    </div>
  </div>
</section>
    <section id="contact" className="bg-blue-600 text-white py-16">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-bold mb-4">{content[language].contact.title}</h2>
        <p className="text-xl mb-8">{content[language].contact.subtitle}</p>

        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
          {/* Telegram - Primary Contact */}
          <a href="https://t.me/petkodonev" className="bg-yellow-500 text-blue-900 font-bold py-3 px-8 rounded-full text-lg hover:bg-yellow-400 transition duration-300 inline-flex items-center justify-center">
          <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7,3.7l-3.1,15.7c-0.2,1.1-0.9,1.4-1.8,0.9l-5-3.7l-2.4,2.3c-0.3,0.3-0.5,0.5-1,0.5l0.4-5.2l9.4-8.5 
                    c0.4-0.4-0.1-0.6-0.6-0.2l-11.6,7.3l-5-1.6c-1.1-0.3-1.1-1.1,0.2-1.6l19.5-7.5C20.1,2.3,21,2.9,20.7,3.7z"/>
          </svg>
          {language === 'en' ? "Get Fast Support on Telegram" : "通过 Telegram 快速联系"}
          </a>

          {/* Email - Secondary Contact for Professionalism */}
          <a href="mailto:petkodonev@gmail.com" className="bg-gray-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-600 transition duration-300 inline-flex items-center justify-center">
            <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12.713l11.995-7.027c-.137-.12-2.627-2.186-11.995-2.186s-11.858 2.066-11.995 2.186l11.995 7.027zm11.995 
                      1.029l-3.995-2.344-8 4.688-8-4.688-3.995 2.344v7.995c.137.12 2.627 2.186 11.995 2.186s11.858-2.066 
                      11.995-2.186v-7.995z"/>
            </svg>
            {language === 'en' ? "Email Us (For Business Inquiries)" : "发送电子邮件（商业咨询）"}
          </a>
        </div>

        <div className="mt-8 flex justify-center space-x-8">
          <div className="flex items-center">
            <MapPin className="mr-2" />
            <span>{content[language].contact.offices.phnomPenh}</span>
          </div>
          <div className="flex items-center">
            <MapPin className="mr-2" />
            <span>{content[language].contact.offices.siemReap}</span>
          </div>
        </div>
      </div>
    </section>
  
    {/* <section id="contact" className="bg-blue-600 text-white py-16">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl font-bold mb-4">{content[language].contact.title}</h2>
        <p className="text-xl mb-8">{content[language].contact.subtitle}</p>
        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
          <a href="tel:+855763654001" className="bg-yellow-500 text-blue-900 font-bold py-3 px-8 rounded-full text-lg hover:bg-yellow-400 transition duration-300 inline-flex items-center justify-center">
            <Phone className="mr-2" /> {content[language].contact.cta} (+855 76 365 4001)
          </a>
          <a href="https://t.me/N30VI" className="bg-blue-400 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-blue-500 transition duration-300 inline-flex items-center justify-center">
            <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.7,3.7l-3.1,15.7c-0.2,1.1-0.9,1.4-1.8,0.9l-5-3.7l-2.4,2.3c-0.3,0.3-0.5,0.5-1,0.5l0.4-5.2l9.4-8.5 c0.4-0.4-0.1-0.6-0.6-0.2l-11.6,7.3l-5-1.6c-1.1-0.3-1.1-1.1,0.2-1.6l19.5-7.5C20.1,2.3,21,2.9,20.7,3.7z"/></svg>
            {content[language].contact.telegram}
          </a>
          <a href="https://www.facebook.com/profile.php?id=61565147296594" target="_blank" rel="noopener noreferrer" className="bg-blue-700 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-blue-800 transition duration-300 inline-flex items-center justify-center">
            <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
            </svg>
            {language === 'en' ? 'Facebook' : 'Facebook'}
          </a>
        </div>
        <div className="mt-8 flex justify-center space-x-8">
          <div className="flex items-center">
            <MapPin className="mr-2" />
            <span>{content[language].contact.offices.phnomPenh}</span>
          </div>
          <div className="flex items-center">
            <MapPin className="mr-2" />
            <span>{content[language].contact.offices.siemReap}</span>
          </div>
        </div>
      </div>
    </section> */}
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-2">Easy Visa Cambodia</h3>
              <p className="text-sm">{content[language].footer.description}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">{content[language].footer.quickLinks}</h3>
              <ul className="text-sm">
                {content[language].footer.links.map((link, index) => (
                  <li key={index}><a href={link.href} className="hover:text-blue-300">{link.name}</a></li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center text-sm">
            <p>{content[language].footer.copyright}</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;